
import { defineComponent, ref, PropType } from 'vue';
import useStatus from './useStatus';

export default defineComponent({
  name: 'TableItem',
  props: {
    server: {
      type: Object as PropType<StatusItem | BoxItem>,
      default: () => ({})
    }
  },
  setup(props) {
    const collapsed = ref(true);
    const utils = useStatus(props);
    return {
      collapsed,
      ...utils
    };
  }
});
