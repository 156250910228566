
import { defineComponent, ref, onMounted, onBeforeUnmount } from 'vue';
import axios from 'axios';

import TheHeader from '@/components/TheHeader.vue';
import TheError from '@/components/TheError.vue';
import ServersTable from '@/components/ServersTable.vue';
import UpdateTime from '@/components/UpdateTime.vue';
import ServersCard from '@/components/ServersCard.vue';
import TheFooter from '@/components/TheFooter.vue';

export default defineComponent({
  name: 'App',
  components: {
    TheHeader,
    TheError,
    ServersTable,
    ServersCard,
    TheFooter,
    UpdateTime
  },
  setup() {
    const servers = ref<Array<StatusItem | BoxItem>>();
    const updated = ref<number>();
    const { interval } = window.__PRE_CONFIG__;
    let timer: number;
    const runFetch = () => axios.get('json/stats.json')
      .then(res => {
        servers.value = res.data.servers;
        updated.value = Number(res.data.updated);
      })
      .catch(err => console.log(err));
    onMounted(() => runFetch() && (timer = setInterval(runFetch, interval * 1000)));
    onBeforeUnmount(() => clearInterval(timer));
    return {
      servers,
      updated
    };
  }
});
